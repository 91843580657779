<template>
  <footer class="text-white pt-5"
    style="background-color: #13322b"
    aria-hidden="true"
    role="document"
    aria-label="Para hacer este sitio web accesible al lector de pantalla, Oprima alt + 1. Para dejar de recibir este mensaje, oprima alt + 2">
    
    <div class="list-info description-xxs line-height-180">
        <div class="container pb-4">
            <div class="row">
                <div class="col-sm-3">
                    <img :src="img_logo" class="img-fluid mb-4">
                </div>

                <div class="col-sm-3">

                <h5 @mouseenter="talk($event)" @mouseleave="stopTalking">
                  Enlaces
                </h5>

                <ul class="list-unstyled">
                  <li>
                    <a @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                        href="#">
                      Participa
                    </a>
                  </li>
                  <li>
                    <a @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                        href="#">
                        Publicaciones Oficiales
                    </a>
                  </li>
                  <li>
                    <a @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                      Marco Jurídico
                      </a>
                  </li>
                  <li>
                    <a @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                      Plataforma Nacional de Transparencia
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none">
                        Alerta
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                    class="text-white text-decoration-none"
                        href="#">
                        Denuncia
                    </a>
                  </li>
                </ul>
                </div>
                <div class="col-sm-3">
                <h5 @mouseenter="talk($event)" @mouseleave="stopTalking"
                    aria-hidden="true">
                  ¿Qué es gob.mx?
                </h5>
                <p @mouseenter="talk($event)" @mouseleave="stopTalking">
                  Es el portal único de trámites, información y participación
                  ciudadana.
                  <a class="text-white text-decoration-none"
                    href="#">
                    Leer más
                </a>
                </p>
                <ul class="list-unstyled">
                  <li>
                    <a @mouseenter="talk($event)" @mouseleave="stopTalking" 
                        class="text-white text-decoration-none"
                      href="#">
                      Portal de datos abiertos
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                        Declaración de accesibilidad
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                      Aviso de privacidad integral
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                      Aviso de privacidad simplificado
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                      Términos y Condiciones
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                      Política de seguridad
                    </a>
                  </li>
                  <li>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                      href="#">
                      Mapa de sitio
                    </a>
                  </li>
                </ul>
                </div>
                <div class="col-sm-3">
                <h5>
                    <a  @mouseenter="talk($event)" @mouseleave="stopTalking"
                        class="text-white text-decoration-none"
                        href="#">
                        Denuncia contra servidores públicos
                    </a>
                </h5>

                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-0" 
                :style="`background-image: url(${img_pleca}); 
                    background-position-y: bottom;
                    height: 50px;`">
                    
                </div>
            </div>
        </div>
    </div>
  </footer>
</template>

<script setup>
    // Images
    const img_logo = require('ASSETS/image/logomexico')
    const img_pleca = require('ASSETS/image/pleca')

    import { talk, stopTalking } from 'COMPOSABLES/speechSynthesis';
</script>
<style scoped>
.zoom-hover:hover {
  transform: scale(1.12);
}
</style>
