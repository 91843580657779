<template>
    <div id="default-template" class="container-fluid p-0 m-0">
        <ws-header/>

        <slot></slot>

        <ws-footer/>
    </div>

    <!-- <div style="
        background-color: white;
        z-index: 9998;
        position: fixed;
        bottom: 50px;
        right: 25px;
        border-radius: 32px;"> 

        <button @click="openAccessibility()"
            style="
                background-color: transparent; 
                border: 4px solid #2c6759;  

                display: flex;
                justify-content: center;
                align-items: center;

                border-radius: 32px;
                padding: 2px 10px 2px 6px;">

            <img :src="icon_accessibility" 
                width="50" 
                height="50" 
                alt="botón de accesibilidad"/>

            <span style="font-size: 16px; font-weight: 900; color: #2c6759;">
                Botón de accesibilidad
            </span>
        </button>
    </div>


    <div :class="`sidebar ${show_sidebar ? 'sidebar-active' : ''}`">

        <div class="sidebar-item" @click="grayscaleActivate()">
            <img :src="icon_colors" class="mr-3">
            <strong>Cambiar escala de grises</strong>
        </div>
        <div class="sidebar-item" @click="invertActivate()">
            <img :src="icon_adjust" class="mr-3">
            <strong>Cambiar el contraste de color</strong>
        </div>
        <div class="sidebar-item" @click="toggleSpeak">
            <img :src="icon_voice" class="mr-3">
            <strong>Usar lector de pantalla</strong>
        </div>
        <div class="sidebar-item" @click="readingGuideActivate()">
            <img :src="icon_reading" class="mr-3">
            <strong>Guía de lectura</strong>
        </div>
        <div class="sidebar-item">
            <div>
                <img :src="icon_search" class="mr-3">
                <strong>Ampliar la pantalla</strong> <br>

                <div style="display: flex; margin: 10px 0px">
                    <button class="btn btn-primary" @click="aplicateZoom('-')">
                        -
                    </button>

                    <div class="flex-center bg-light px-3 mx-3">
                        {{ zoom }}    
                    </div>
                    

                    <button class="btn btn-primary" @click="aplicateZoom('+')">
                        +
                    </button>
                </div>
                
            </div>
            
        </div>


        <div class="sidebar-item" @click="closeAccessibility()" >
            Cerrar
        </div>

    </div>

    <div id="reading-cursor" class="reading-cursor">
        
    </div> -->

</template>

<script setup>
    // images
    const icon_accessibility = require('./../assets/icons/accessibilty.svg')
    const icon_colors = require('./../assets/icons/colors.svg')
    const icon_adjust = require('./../assets/icons/adjust.svg')
    const icon_voice = require('./../assets/icons/voice.svg')
    const icon_reading = require('./../assets/icons/reading.svg')
    const icon_search = require('./../assets/icons/search.svg')

    // 
    import { ref, computed } from 'vue'
    import { useStore } from'vuex'
    import { useRouter } from 'vue-router'
    
    // hooks
    const store = useStore()
    const router = useRouter()

    // variables
    const show_sidebar = ref(false)
    const zoom = ref(100)
    const visible_cursor = ref(false)

    // methods
    // const grayscaleActivate = () => {
    //     let body = document.getElementsByTagName('body')[0];
    //     body.style.filter = (body.style.filter == "grayscale(100%)") ? "grayscale(0%)" : "grayscale(100%)"
    // }

    // const invertActivate = () => {
    //     let body = document.getElementsByTagName('body')[0];
    //     body.style.filter = (body.style.filter == "invert(100%)") ? "invert(0%)" : "invert(100%)"
    // }

    // const aplicateZoom = (type) => {
    //     let template = document.getElementById('default-template');
    //     zoom.value = (type == '-') ? zoom.value - 10 : zoom.value + 10
    //     template.style.zoom = `${zoom.value}%`
    // }

    // const readingGuideActivate = (type) => {
    //     visible_cursor.value = !visible_cursor.value

    //     if(visible_cursor.value) {
    //         addEventListener("mousemove", changeCursor);
    //     }
    //     else {
    //         let reading_cursor = document.getElementById('reading-cursor')
    //         reading_cursor.style.visibility = `hidden`

    //         removeEventListener("mousemove", changeCursor);
    //     }
    // }
    // function changeCursor(event) {
    //     let reading_cursor = document.getElementById('reading-cursor')
    //     reading_cursor.style.visibility = `visible`

    //     if(reading_cursor !== null) {
    //         reading_cursor.style.top = `${event.pageY + 20}px`
    //     }
    // }


    // const toggleSpeak = () => {
    //     store.commit('toggleSpeak');
    // }


    // const openAccessibility = () => {
    //     show_sidebar.value = true
    // }
    // const closeAccessibility = () => {
    //     show_sidebar.value = false
    // }

</script>

<style lang="scss" scoped>
    #default-template {
        min-height: 100vh;
    }

    .sidebar {
        position: fixed; 
        top: 0px; 
        right: -280px; 
        z-index: 9999; 
        transition: all .5s; 

        height: 100%; 
        width: 280px; 
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        
        padding-top: 40px;
        padding-bottom: 40px;

        &-item {
            width: 100%; 
            min-height: 50px;
            padding: 10px 0px;
            border-bottom: 2px solid gray;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            // cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAb5JREFUaEPtmGtywjAMhOV7lJ4FTlY4WcNVSs9Rd4yHgQQ/VrIsJzPJH36Qhz7vSuvE0cYPt/H6aQcYrSCuwIc/kqMvIgq/F/px59HFh+fjAAfvZwWvBEIO8KAZDMIB+L7bJ3UMhNABiFAT3dzJui80AWLtxmrgAJ/+TP4+heqHIQQOEMdo6AP8MADpC2Bgqf4AnRscBwiFLMMMN1O3BrcF6GApLkA+zAapMQ5ASQ0eACcLcEWaEnwNAE0NzgOQhBmuhGgbsi4AwRadB6CRBagi4DZkvQBggksA9LIAVcPTiX7dlH6XQm/yOO/g7QEKL0vbUCAs3s0la+UD9Amzkg8m8nTRs1DvLAh2cXSlP4qez3j/OXG5PaALwCpWp4lbAcJ8D6tbWVl0Xfk90B5mTZu3JZgFQPDy/IMYmLKIClIANAviaqcmVyGckMLlTRwthAG8Fvl+jYqVZAogWbC0Sar5FVToA5DzeEq5TMKiNpIBlEZpqUHT1zVZSRugXoxyQ8sAclmA2kGxoVsA5pOI05ApKwmzQQ7wWgSn+Nx7hTkAOiZK58VvrcXtcu0xcgVqdzb6fwcwWujsY3YFRivwDx9s2TE7wWvDAAAAAElFTkSuQmCC"), auto;
        }
    }
    .sidebar-active {
        right: 0px !important;
    }

    .reading-cursor {
        position: absolute; 
        width: 100%; 
        height: 4px; 
        background-color: blue; 
        z-index: 9997;
        visibility: hidden;
    }
</style>
