import { createRouter, createWebHistory } from 'vue-router'
import Home from 'ROUTER/modules/home'

const routes = [
  ...Home
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
