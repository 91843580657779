import { createStore } from 'vuex'

export default createStore({
    state: { 
        enable_speak: false
    },
    getters: { 
        getSpeak(state) {
            return state.enable_speak
        }
    },
    mutations: { 
        toggleSpeak(state){
            state.enable_speak = !state.enable_speak
        }
    },
    actions: { },
    modules: { }
})
