import { useSpeechSynthesis } from "@vueuse/core";
import { computed } from 'vue';
import store from '../store';

const enable_speak = computed(() => (store.getters['getSpeak']));

function talk(event){
    if(enable_speak.value) {
        const { speak } = useSpeechSynthesis(event.target.innerText,{
            lang: 'es-Mx',
            pitch: 1,
            rate: 1,
            volume: 1
            });
        speak();
    }
}

function stopTalking(){
    speechSynthesis.cancel()
}

export { talk, stopTalking}